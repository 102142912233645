import * as React from "react";
import { useEffect } from "react";

const CorpusPage = () => {
    useEffect(()=>{
      if (typeof window !== 'undefined') {
        window.location = 'https://blog.andovar.com/corpus';
      }
    },[])
  return <></>;
};
export default CorpusPage;
